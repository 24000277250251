export const successStyle = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}
export const websiteRegex = /^(https?:\/\/)?((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|\d{1,3}(\.\d{1,3}){3}|localhost)(:\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/i;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{7,}$/;

export const errorStyle = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
}
export const languagueConvert = {
    'English [UK,US,etc]': 'en',
    '中国 [Peoples Republic of China]': 'cn',
    "中國香港 [HKSAR, China]": "hk",
    "台灣 [Taiwan]": "tw",
    "にほん (日本) [Japan]": "jp",
    "북한(Bukhan) [North Korea]": "kn",
    "대한민국(Daehan Minguk) [South Korea]": "ks",
    "España [Spain]": "es",
    "Français [France]": "fr",
    "Tiếng Việt [Vietnam]": "vi"
}