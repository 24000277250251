import React, { useEffect } from "react";
import footerLogo from "../../../../assets/img/footer-logo.png";
import globe from "../../../../assets/img/globe.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLanguage = useSelector((state) => state.user.language);
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    dispatch({ type: "changeLanguage", data: selectedLanguage });
  };

  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5">
              <div className="footer-logo">
                <a onClick={() => navigate("/")}>
                  <img src={footerLogo} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="row justify-content-end">
                <div className="col-lg-3 col-md-4">
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a href="#/" onClick={() => navigate("/")}>
                          {t("home")}
                        </a>
                      </li>
                      <li>
                        <a href="#/" onClick={() => navigate("/about")}>
                          {t("learn-more")}
                        </a>
                      </li>
                      <li>
                        <a href="#/" onClick={() => navigate("/pricing")}>
                          {t("pricing")}
                        </a>
                      </li>
                      <span className="inline-container">
                        <img src={globe} height="24" width="24" />
                        <div className="language-picker js-language-picker">
                          <select
                            name="language-picker-select"
                            id="language-picker-select"
                            onChange={handleLanguageChange}
                            value={currentLanguage}
                          >
                            <option value="en" className="nice-select">
                              {" "}
                              English [UK,US,etc]
                            </option>
                            <option value="hk" className="nice-select">
                              {" "}
                              中國香港 [HKSAR, China]
                            </option>
                            <option value="cn" className="nice-select">
                              {" "}
                              中国 [Peoples Republic of China]
                            </option>
                            <option value="tw" className="nice-select">
                              {" "}
                              台灣 [Taiwan]
                            </option>
                            <option value="jp" className="nice-select">
                              {" "}
                              にほん (日本) [Japan]{" "}
                            </option>
                            <option value="kn" className="nice-select">
                              {" "}
                              북한(Bukhan) [North Korea]{" "}
                            </option>
                            <option value="ks" className="nice-select">
                              {" "}
                              대한민국(Daehan Minguk) [South Korea]{" "}
                            </option>
                            <option value="es" className="nice-select">
                              {" "}
                              España [Spain]
                            </option>
                            <option value="fr" className="nice-select">
                              {" "}
                              Français [France]
                            </option>
                            <option value="vi" className="nice-select">
                              {" "}
                              Tiếng Việt [Vietnamese]
                            </option>

                          </select>
                        </div>
                      </span>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a href="#/" onClick={() => navigate("/faq")}>
                          {t("faq")}
                        </a>
                      </li>
                      <li>
                        <a
                          href="register"
                          onClick={() => navigate("/register")}
                        >
                          {t("signup")}
                        </a>
                      </li>
                      <li>
                        <a href="#/" onClick={() => navigate("/support")}>
                          {t("support")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a href="login" onClick={() => navigate("/login")}>
                          {t("login")}
                        </a>
                      </li>
                      <li>
                        <a href="terms" onClick={() => navigate("/terms")}>
                          {t("terms-of-service")}
                        </a>
                      </li>
                      <li>
                        <a href="policy" onClick={() => navigate("/policy")}>
                          {t("policy")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text">
                  <p>
                    MONITOR-24 © 2023 | {t("slogan")} | Powered by{" "}
                    <b>
                      <a src="http://futurembrace.com">Future Embrace LTD</a>{" "}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
