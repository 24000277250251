import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PlanInfo = ({ data }) => {
  const userData = useSelector((state) => state.user.userData);
  const { t } = useTranslation();
  const items = t(data.desc)
    .split(",")
    .map((item, index) => <div key={index}>{item}</div>);
  if (!data) return null;
  return (
    <div className="col-lg-4 col-md-6">
      <div className="pricing-card">
        <div className="pricing-title">
          <h2>{t(data.title)}</h2>
          <span>{t(data.sub)}</span>
        </div>
        <div className="price_txt">
          <h2>
            {`US$` + data.price}{" "}
            <span>
              /{[10, 11, 12].includes(data.uid) ? t("year") : t("month")}
            </span>
          </h2>
        </div>
        <div className="pricing-list">
          <ul>
            <li>{items}</li>
          </ul>
        </div>
        {!data.active ? (
          <div className="buy-btn">
            <a
              href={
                data.url +
                `?client_reference_id=${
                  userData.accountid ? userData.accountid : 0
                }`
              }
              className="primary-btn"
            >
              {t("buy-now")} <i className="far fa-arrow-right"></i>
            </a>
          </div>
        ) : (
          <div className="buy-btn">
            <div className="checked-btn">
              {t("current-plan")}
              <i className="far fa-check"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanInfo;
