import React from "react";
import logo from "../../../../assets/img/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IconDropdown from "../../molecules/flagSelect";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const isSlideMenu = useSelector((state) => state.app.isSlideMenu);

  const toggleSlideMenu = () => {
    dispatch({ type: "toggleSlideMenu", data: !isSlideMenu });
  };
  return (
    <header>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2 col-md-1 col-4">
            <div className="logo">
              <a href="/">
                <img src={logo} alt="" />
              </a>
            </div>
          </div>

          <div className="col-lg-10 col-md-11 col-8">
            <div className="header-right-wrapper">
              <div className="mainmenu-wrap">
                <div className="mainmenu">
                  <ul>
                    <li>
                      <a
                        className={location.pathname === "/" ? "active" : ""}
                        onClick={() => navigate("/")}
                      >
                        {t("home")}
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className={
                          location.pathname === "/agent-list" ? "active" : ""
                        }
                        onClick={() => navigate("/agent-list")}
                      >
                        {t("agents")}
                      </a>
                    </li> */}

                    <li>
                      <a
                        className={
                          location.pathname === "/about" ? "active" : ""
                        }
                        onClick={() => navigate("/about")}
                      >
                        {t("learn-more")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          location.pathname === "/pricing" ? "active" : ""
                        }
                        onClick={() => navigate("/pricing")}
                      >
                        {t("pricing")}
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          location.pathname === "/support" ? "active" : ""
                        }
                        onClick={() => navigate("/support")}
                      >
                        {t("support")}
                      </a>
                    </li>
                    <li>
                      <IconDropdown />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="header-btn">
                <a onClick={() => navigate("/login")} className="primary-btn">
                  {t("login")} <i className="far fa-arrow-right"></i>
                </a>
              </div>

              <div className="menu_trigger" onClick={toggleSlideMenu}>
                <i className="fal fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
