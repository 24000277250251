import React from "react";
import about1Img from "../../../assets/img/about-1.png";
import about5Img from "../../../assets/img/about-image-5.png";
import about6Img from "../../../assets/img/about-img-6.png";
import about7Img from "../../../assets/img/about-img-7.png";
import about2Img from "../../../assets/img/about-img-2.png";
import about3Img from "../../../assets/img/about-img-3.png";
import { useTranslation } from "react-i18next";

const LearnMorePage = () => {
  const { t } = useTranslation();
  return (
    <div className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="about-wrapper">
              <div className="about-grid grid-left">
                <div className="about-wrap wrap_one">
                  <div className="about-content">
                    <div className="area-title">
                      <h2>{t("about-our-platform")}</h2>
                    </div>
                    <p>{t("about-our-platform-text-1")}</p>
                  </div>
                  <div className="about-thumb thumb-1">
                    <img src={about1Img} alt="" />
                  </div>
                  <span className="count_item">01</span>
                </div>

                <div className="about-wrap mt-5">
                  <div className="about-content pb-5">
                    <div className="area-title">
                      <h2>{t("alerts")}</h2>
                    </div>
                    <p>{t("alerts-text-1")} </p>
                    <div className="alearts-list">
                      <ul>
                        <li>{t("email")}</li>
                        <li>{t("sms")}</li>
                        <li>{t("instant-messenger")}</li>
                        <li>{t("website")}</li>
                        <li>{t("rss-feed-text")}</li>
                      </ul>
                    </div>
                    <p>{t("alerts-text-2")}</p>
                  </div>
                  <div className="about-thumb thumb-2 mt-5">
                    <img src={about5Img} alt="" />
                  </div>
                </div>

                <div className="about-wrap mt-5 pt-5">
                  <div className="about-content">
                    <div className="area-title">
                      <h2>{t("there-is-more")}</h2>
                      <p>{t("there-is-more-text-1")}</p>
                    </div>
                    <div className="alearts-list">
                      <ul>
                        <li>{t("there-is-more-text-1.1")}</li>
                        <li>{t("there-is-more-text-1.2")}</li>
                        <li>{t("there-is-more-text-1.3")}</li>
                        <li>{t("there-is-more-text-1.4")}</li>
                        <li>{t("there-is-more-text-1.5")}</li>
                        <li>{t("there-is-more-text-1.6")}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="about-thumb thumb-6 mb-5">
                    <img src={about6Img} alt="" />
                  </div>
                </div>

                <div className="about-wrap">
                  <div className="about-content">
                    <div className="area-title">
                      <h2>{t("developers")}</h2>
                      <p>{t("developer-text-1")}</p>
                    </div>
                    <p>{t("developer-text-1.1")}</p>
                  </div>
                  <div className="about-thumb thumb-6 mb-5">
                    <img src={about7Img} alt="" />
                  </div>
                </div>

                <div className="about-wrap wrap_four mt-5 pt-3">
                  <div className="about-content">
                    <div className="area-title">
                      <h2>{t("security")}</h2>
                      <p>{t("security-text-1")}</p>
                    </div>
                    <div className="alearts-list">
                      <ul>
                        <li>{t("security-text-1.1")}</li>
                        <li>{t("security-text-1.2")}</li>
                        <li>{t("security-text-1.3")}</li>
                        <li>{t("security-text-1.4")}</li>
                        <li>{t("security-text-1.5")}</li>
                      </ul>
                    </div>
                  </div>
                  <span className="count_item">04</span>
                </div>
              </div>

              <div className="about-grid grid-right">
                <div className="about-wrap wrap_two">
                  <div className="about-thumb thumb-1">
                    <img src={about2Img} alt="" />
                  </div>
                  <div className="about-content mt-5 pt-5">
                    <div className="area-title">
                      <h2>{t("monitoring")}</h2>
                    </div>
                    <p>{t("monitoring-text-1")}</p>

                    <div className="monitoring-lists">
                      <ul>
                        <li>
                          <span>HTTP</span> <p>{t("http-text")}</p>
                        </li>
                        <li>
                          <span>HTTPS</span> <p>{t("https-text")}</p>
                        </li>
                        <li>
                          <span>FTP</span> <p>{t("ftp-text")}</p>
                        </li>
                        <li>
                          <span>SSH</span> <p>{t("ssh-text")}</p>
                        </li>
                        <li>
                          <span>SIP</span> <p>{t("sip-text")}</p>
                        </li>
                        <li>
                          <span>Port</span> <p>{t("port-text")}</p>
                        </li>
                        <li>
                          <span>Ping</span> <p>{t("ping-text")}</p>
                        </li>
                      </ul>
                    </div>

                    <p>{t("monitor-text-2")}</p>

                    <div className="monitoring-lists">
                      <ul>
                        <li>
                          <span>MonAgent</span> <p>{t("monagent-text")}</p>
                        </li>
                        <li>
                          <span>CURL</span> <p>{t("curl-text")}</p>
                        </li>
                        <li>
                          <span>MonAccess</span> <p>{t("monaccess-text")}</p>
                        </li>
                        <li>
                          <span>MonPing</span> <p>{t("monping-text")}</p>
                        </li>
                        <li>
                          <span>MonHTTP</span> <p>{t("monhttp-text")}</p>
                        </li>
                        <li>
                          <span>MonPort</span> <p>{t("monport-text")}</p>
                        </li>
                        <li>
                          <span>MonDns</span> <p>{t("mondns-text")}</p>
                        </li>
                      </ul>
                    </div>
                    <p>{t("monitor-text-3")}</p>
                    <p>{t("monitor-text-4")}</p>
                  </div>
                  <span className="count_item">02</span>
                </div>
                <div className="about-wrap wrap_three">
                  <div className="about-thumb thumb-3 mt-5">
                    <img src={about3Img} alt="" />
                  </div>
                  <div className="about-content mt-5">
                    <div className="area-title">
                      <h2>{t("who-is-it-for")}</h2>
                    </div>
                    <p>{t("who-is-it-for-text-1")}</p>

                    <div className="alearts-list">
                      <ul>
                        <li>{t("who-is-it-for-text-1.1")}</li>
                        <li>{t("who-is-it-for-text-1.2")}</li>
                        <li>{t("who-is-it-for-text-1.3")}</li>
                        <li>{t("who-is-it-for-text-1.4")}</li>
                        <li>{t("who-is-it-for-text-1.5")}</li>
                      </ul>
                    </div>
                  </div>
                  <span className="count_item">03</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMorePage;
