export const convertFormat = (inputStr) => {
    try {
        // Split the input string into date and time components
        const [datePart, timePart] = inputStr.split(' ');

        // Split the date part into year, month, and day components
        const [year, month, day] = datePart.split('-');

        // Split the time part into hour, minute, and second components
        const [hour, minute, second] = timePart.split(':');

        // Create a Date object using the components
        const inputDate = new Date(year, month - 1, day, hour, minute, second);

        // Define options for formatting
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
        };

        // Format the Date object using Intl.DateTimeFormat
        const outputStr = new Intl.DateTimeFormat('en-US', options).format(inputDate);

        return outputStr;
    } catch (error) {
        console.log(error)
    }

};
export function transformArray(inputArray) {
    const resultArray = [];

    inputArray
        .filter(item => item.isEnable) // Filter only items with isEnable === true
        .forEach((item, index) => {
            const transformedItem = {
                messenger: item.type,
                recipient: item.value,
                priority: index + 1, // Set priority based on the index (starting from 1)
            };
            resultArray.push(transformedItem);
        });

    return resultArray;
}
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function getKeyByValue(object, value) {
    if (!object || !value) return
    return Object.keys(object).find(key => object[key] === value);
}

export function reverseTransformArray(array1, array2) {
    if (!array1 || !array2) return
    const resultArray = [];

    array2.forEach((item) => {
        const matchingItem = array1.find((originalItem) => originalItem.type === item.messenger);

        if (matchingItem) {
            resultArray.push({
                ...matchingItem,
                value: item.recipient,
                isEnable: true, // Assuming all items in array2 should be marked as enabled
            });
        }
    });

    // Add items from array1 that are not in array2
    array1.forEach((item) => {
        const existsInArray2 = array2.some((originalItem) => originalItem.messenger === item.type);

        if (!existsInArray2) {
            resultArray.push(item);
        }
    });

    return resultArray;
}