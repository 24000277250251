import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAPIActionJSON } from "../../api/ApiActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { errorStyle, successStyle } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const toastId = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      handleResetPassword();
    }
  };
  const handleResetPassword = () => {
    try {
      if (token) {
        if (password !== confirmPassword) {
          toast.dismiss();
          toastId.current = toast.error(t("toast-reset-pw-unmatch"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        }
        dispatch(
          getAPIActionJSON(
            "resetPassword",
            {
              password: password,
              passwordconfirm: confirmPassword,
            },
            (e) => handleError(e),
            null,
            (e) => handleSuccess(e),
            `/${token}`
          )
        );
      } else {
        dispatch(
          getAPIActionJSON(
            "forgotPassword",
            { email: email },
            (e) => handleError(e),
            null,
            (e) => handleSuccess(e)
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleError = (response) => {
    toast.dismiss();
    toastId.current = toast.error(t("toast-reset-pw-fail"), errorStyle);
  };
  const handleSuccess = (response) => {
    if (response.success) {
      if (token) {
        toast.dismiss();
        toastId.current = toast.success(
          t("toast-reset-pw-success"),
          successStyle
        );
        navigate("/login");
        return;
      }
      toast.dismiss();
      toastId.current = toast.success(t("toast-reset-email-sent"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className="login-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="area-title">
              <h2>{t("reset-pw").toUpperCase()}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="login-form">
              <form action="">
                {token ? (
                  <>
                    <div className="form-item">
                      <label for="">{t("password")}</label>
                      <div className="field-single">
                        <input
                          onKeyDown={handleKeydown}
                          type="password"
                          placeholder={t("password")}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-item">
                      <label for="">{t("confirm-password")}</label>
                      <div className="field-single">
                        <input
                          onKeyDown={handleKeydown}
                          type="password"
                          placeholder={t("password")}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="form-item">
                    <label for="">{t("email")}</label>
                    <div className="field-single">
                      <input
                        onKeyDown={handleKeydown}
                        type="text"
                        placeholder={t("email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div className="form-item">
                  <a
                    onClick={handleResetPassword}
                    tabIndex="0"
                    role="button"
                    className="primary-btn"
                  >
                    {t("reset-pw")} <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </form>
              {!token ? (
                <div className="another-option">
                  <h5>{t("or")}</h5>
                  <a
                    onClick={() => navigate("/login")}
                    className="secondary-btn"
                  >
                    {t("login")}
                    <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
