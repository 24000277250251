import React from "react";

const TermsPage = () => {
  return (
    <div style={{ margin: 10 }}>
      <h1>Last Updated: July 22nd, 2024</h1>

      <h2>Terms of Service</h2>

      <p>
        THE TERMS OF SERVICE (“TERMS”) SET FORTH BELOW DESCRIBE THE TERMS AND
        CONDITIONS THAT SHALL GOVERN YOUR USE OF THE WEBSITE WWW.MONITOR-24.COM
        AND ALL OF ITS SUBDOMAINS (THE “WEBSITE”), THE SERVICES PROVIDED BY THE
        WEBSITE (THE “SERVICES”) AND ALL SOFTWARE DOWNLOADED BY YOU FROM THE
        WEBSITE. BY USING THE WEBSITE AND/OR THE SERVICES, YOU ARE AGREEING TO
        THE FOLLOWING TERMS. IF YOU DO NOT AGREE TO THE TERMS THEN YOU MAY NOT
        ACCESS THE WEBSITE NOR USE THE SERVICES.
      </p>

      <p>
        The Website is owned and operated by Future Embrace Limited and its
        affiliates (“MONITOR-24”). Future Embrace Limited may amend these Terms
        at any time by posting the amended Terms on the Website. Except as 
        stated below, all amended Terms shall automatically be effective upon
        the posting thereof to the Website.
      </p>

      <h2>Services</h2>

      <p>
        <strong>Services Offered:</strong> The Website offers a wide range of
        Services, including Network Monitoring, email notifications and network
        monitoring software downloads (“Software Programs”).
      </p>

      <p>
        <strong>Registration:</strong> In order to utilize Services, you may be
        required to create a user account and to register your user information
        with MONITOR-24. As part of the registration process, you will be asked
        to select a user name and password and to provide MONITOR-24 with
        certain information. Selection and use of your user name and password
        are subject to the Terms hereof.
      </p>

      <p>
        <strong>Software Programs:</strong> In order to access and use certain
        Services offered by the Website, you may need to download one or more of
        the Software Programs (link(s)). MONITOR-24 may charge a fee for downloads
        of certain Software Programs (“Paid Software Programs”). The downloading
        and use of the Software Programs are subject to these Terms and, in the
        case of the Paid Software Programs, also to the terms and conditions of
        the Software License Agreement, which can be read here (“Software
        License Agreement”).
      </p>
      <p>
        <strong>Fees and Charges:</strong> MONITOR-24 may charge a fee for use
        of and access to some or all of the Services (“Paid Services”). Your
        continued ability to access and use Paid Services will be subject to
        your payment of the applicable fees charged by MONITOR-24 from time to
        time. Certain Services may be available only to those users who paid for
        and downloaded the applicable Paid Computer Program required in order to
        use such Services. Even if you have paid to download one or more Paid
        Software Programs, a fee may be charged for access by the Paid Software
        Programs to certain Paid Services.
      </p>
      <p>
        <strong>Reservation of Rights:</strong> MONITOR-24 reserves the right
        to, at any time, at its sole discretion and without prior notice: (i)
        make certain Services previously offered free of charge subject to
        payment of a fee, and vice versa; (ii) increase or decrease the fee
        charged for certain Services; (iii) discontinue all or any of the
        Services (subject to repayment of fees paid in advance as set forth in
        Section 6 below); (iv) add additional Services and/or (v) change, alter
        or otherwise modify the Services offered.
      </p>
      <p>
        <strong>Discontinuation of Paid Services:</strong> Subscriptions are
        generally non-refundable, and cancellations take effect at the end of
        the current billing period without partial refunds.  Customers who
        terminate their subscription are not entitled to refunds of pre-paid fees.
        Within 30 days of the discontinuation of any Paid Services by MONITOR-24,
        MONITOR-24 will refund to you any fees previously received from you in
        respect of the period following such discontinuation. Without derogating
        from the provisions of Section 19 below, refund of prepaid fees as
        aforesaid shall be the sole liability of MONITOR-24 to you in the event
        of discontinuation of Services.
      </p>
      <p>
        <strong> Internet; Disclaimer:</strong> Access to and utilization of the
        Services offered through the Software Programs requires that the
        computer system on which the Software Program is running be connected to
        the internet in order to allow the Software Program to connect and to
        transmit electronic data to the Website. MONITOR-24 HAS NO CONTROL OVER
        THE CONTINUAL OR PROPER FUNCTIONING OF THE INTERNET OR OF YOUR INTERNET
        CONNECTION AND THEREFORE, WITHOUT DEROGATING FROM THE PROVISIONS OF
        SECTIONS 18 AND 19 BELOW, LICENSOR SHALL NOT BE RESPONSIBLE OR LIABLE
        UNDER ANY CIRCUMSTANCES FOR ANY FAILURE TO ACCESS THE SERVICES ARISING
        FROM OR RELATED TO THE FUNCTIONING OF THE INTERNET AND/OR OF YOUR
        INTERNET CONNECTION. Availability
      </p>
      <p>
        The Services and Software Programs are available only to individuals
        and/or entities who can form legally binding contracts under applicable
        law (for example, adults over the age of 18). By registering for the
        Services and creating a user account you represent to MONITOR-24 that
        you are of legal age and capacity to form a binding contract under
        applicable law User Conduct
      </p>
      <p>
        <strong>General:</strong> You agree to use the Services in a manner that
        demonstrates good taste and respect for the rights of MONITOR-24 and of
        third parties. In case of a discrepancy between the English and other 
        versions of this contract, the English version shall prevail.
      </p>
      <p>
        <strong>Prohibited Use:</strong> You agree that you will not use the
        Services for any purpose that is illegal, prohibited by these Terms or
        that is otherwise deemed offensive by MONITOR-24. To that end, you
        agree, by way of example and not by way of limitation, that you will
        not: (1) use the Website and/or the Services in a manner that infringes
        the rights of a third party, including, without limitation, copyrights,
        trademarks, patents, trade secrets and other intellectual property
        rights, rights of privacy and publicity; (2) use the Website and/or the
        Services in a manner that is libelous, defamatory, slanderous, contains
        pornographic or other explicitly sexual content, denigrates or offends
        any ethnic, racial, gender, religious or other protected group, through
        use of language, images, stereotypical depiction or otherwise, designed
        to or does harass, threaten, defame or abuse others, encourages or
        depicts the conduct of other illegal activity, that contains or links to
        any viruses or any other programs or technology designed to disrupt or
        damage any software or hardware or is generally offensive or in bad
        taste; (3) use the Site and/or the Services to create or distribute
        “spam” e-mail messages; (4) interfere with the Services by using
        viruses or any other programs or technology designed to disrupt or
        damage any software or hardware or to by pass any measures employed by
        MONITOR-24 to restrict access to any part of the Website; (5)
        impersonate another person or entity; (6) engage in any activity that
        interferes with any third party’s ability to use or enjoy the Services
        or with MONITOR-24’s ability to provide the Services; (7) assist any
        third party in engaging in any activity prohibited by these Term, (8) 
        unauthorized surveillance or intelligence gathering; (9) analysis for 
        unlawful or discriminatory purposes; or (10) targeting individuals based
        on sensitive personal information.
      </p>
      <p>
        <strong>Removal of Prohibited Content:</strong> MONITOR-24 may at any
        time without prior notice remove any or all content posted to the
        Website in contravention of these Terms or otherwise deemed
        inappropriate by MONITOR-24 in its sole discretion. User Responsibility
      </p>
      <p>
        <strong>User Responsibility on Content:</strong> You understand that all
        information, whether publicly posted or privately transmitted by users of
        the Services, is the sole responsibility of the person / company from whom
        such content originated. This also means that you and/ your comany, and not
        MONITOR-24, are entirely responsible for all content that you upload or
        otherwise make available via the Services. MONITOR-24 does not control the
        content posted to the Website by users via the Services and, as such, does
        not guarantee the legality, non-infringement, accuracy, integrity or quality
        of such content. Therefore, and without derogating from Sections 18 and
        19 below, under no circumstances will MONITOR-24 be liable in any way
        for any content displayed on or otherwise included in the Website or
        transmitted via use of the Services by users, including, but not limited
        to, any errors or omissions in any content, or any loss or damage of any
        kind incurred as a result of the use of any content posted, emailed,
        transmitted or otherwise made available via the Services. Site Content
      </p>
      <p>
        <strong> Third Party Content:</strong>The Website includes forums to
        which users can post content (“Third Party Content”). MONITOR-24 does
        not necessarily review all Third Party Content prior to its posting to
        the Website and makes no warranties or representations with respect to
        Third Party Content, including with respect to its legality,
        non-infringement, offensiveness, ownership and content. MONITOR-24 may
        remove any content displayed on and/or posted to the Website at its sole
        discretion and without prior notice. MONITOR-24 SHALL IN NO WAY OR
        MANNER BE RESPONSIBLE FOR THE ACCURACY OF THIRD PARTY CONTENT INCLUDING
        FEEDBACK RECEIVED FROM OTHER USERS OF THE SITE AND YOU RELY ON THE
        ACCURACY OF SUCH CONTENT AT YOUR OWN RISK. If you feel that any Third
        Party Content infringes any of your rights, please contact
        support@MONITOR-24.com. In order to allow MONITOR-24 to process your
        complaint, please include the exact URL of the allegedly infringing
        content in your e-mail message.
      </p>
      <p>
        <strong> Links:</strong>The Website may contain links to third party
        websites, whether posted by MONITOR-24 or by users of the Services.
        MONITOR-24 has no control over and makes no representations with respect
        to the content or any other aspect of such third party websites and will
        have no responsibility or liability with respect therewith. Intellectual
        Property Rights
      </p>
      <p>
        <strong>Ownership of Rights:</strong>The Services and any software used
        in connection with the Services including the Software Programs
        (collectively, “Site Software”) contain proprietary and confidential
        information that is protected by applicable intellectual property and
        other laws and treaties. Content contained in third-party advertisements
        or information presented to you through the Services or advertisers is
        protected by copyrights, trademarks, service marks, patents, publicity
        rights, or other proprietary rights and laws. MONITOR-24 will solely and
        exclusively own all intellectual property and other rights, title and
        interest in and to the Services and the Site Software, and MONITOR-24
        will have the sole and exclusive right to obtain trademark and copyright
        registrations, patents and other protection therefore and for any
        continuations and derivative works thereof. You will not by use of the
        Services or accessing the Website acquire any right, title or interest
        in any rights of MONITOR-24 under these Terms or otherwise.
      </p>
      <p>
        <strong> MONITOR-24 Content:</strong> MONITOR-24 Content. The content
        displayed on the Website is copyright of MONITOR-24. Other than as
        specifically provided by the Services, none of the content on our site
        may be copied, reproduced, distributed, republished, downloaded,
        displayed, posted or transmitted in any form or by any means, including,
        but not limited to, electronic, mechanical, photocopying, recording, or
        otherwise, without the prior express written permission of MONITOR-24 or
        in accordance with the Services.
      </p>
      <h2>Privacy</h2>
      <p>
        Our current Privacy Policy is available here and is incorporated herein
        by reference. We may change our Privacy Policy from time to time and our
        changes are effective as set forth in the Privacy Policy.
      </p>
      <h2>No Warranty</h2>

      <p>
        MONITOR-24 PROVIDES THE WEBSITE, SERVICES AND SOFTWARE PROGRAMS "AS IS"
        AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY.
        MONITOR-24 DOES NOT WARRANT THAT THE WEBSITE, SERVICES OR SOFTWARE
        PROGRAMS SHALL BE ERROR-FREE, THAT THEY SHALL OPERATE UNINTERRUPTED OR
        THAT THEY SHALL MEET YOU REQUIREMENTS. MONITOR-24 DISCLAIMS ANY AND ALL
        WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
        ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, PERFORMANCE, ACCURACY, RELIABILITY AND NON-INFRINGEMENT. Some
        states do not allow the disclaimer of implied warranties, so the
        foregoing disclaimer may not apply to you. Limitation of Liability
      </p>
      <p>
        Notwithstanding anything herein to the contrary, MONITOR-24’s sole and
        cumulative liability to you or any third party for any loss, cost or
        damage resulting from any claims, demands or actions arising out of or
        relating to these Terms and/or the Software License Agreement and/or
        your use of the Website and/or the Services and/or the Software Programs
        shall not exceed the greater of (i) one US dollar (US$ 1) and (ii) the
        fees actually paid by you to MONITOR-24 for Services and/or Software
        Programs during the twelve (12) month period immediately preceding the
        occurrence of the event giving rise to the applicable loss, cost or
        damage. IN NO EVENT SHALL MONITOR-24 BE LIABLE FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES,
        INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, REVENUE,
        GOODWILL, USE, DATA, ELECTRONICALLY TRANSMITTED REQUESTS OR OTHER
        ECONOMIC ADVANTAGE (EVEN IF MONITOR-24 HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES), HOWEVER CAUSED AND REGARDLESS OF THE
        THEORY OF LIABILITY, WHETHER IN CONTRACT (INCLUDING FUNDAMENTAL BREACH),
        TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR RELATED TO
        THE USE OF OR THE INABILITY TO USE THE WEBSITE AND/OR THE SERVICES
        AND/OR THE SOFTWARE PROGRAMS FOR ANY REASON. SOME STATES MAY NOT ALLOW
        THE LIMITATION OR EXCLUSION OF LIABILITY FOR INDIRECT, INCIDENTAL OR
        CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
        APPLY TO YOU. Qualifications and Limitations Basis of Bargain
      </p>
      <p>
        The limited warranty, exclusive remedies and limited liability
        provisions set forth herein are fundamental elements of these Terms, and
        you accept and confirm that MONITOR-24 would not be able to provide the
        Services or the Software Programs on an economic basis without such
        limitations.
      </p>
      <h2>Legal Compliance</h2>
      <p>
        You shall comply with all applicable domestic and international laws,
        statutes, ordinances and regulations regarding your use of the Services.
        Survival
      </p>
      <p>
        All provisions of these Terms which by their subject matter should
        survive termination shall survive termination including suspension of
        your account and/or rights to use the Services or any part thereof,
        including without limitation provisions pertaining to ownership,
        intellectual property, warranty and liability.
      </p>
      <h2>Notices</h2>

      <p>
        Except as explicitly stated otherwise, any notices shall be given by
        postal mail to Future Embrace Limited, Suite D, 7/F, Hoi To Court,
        275 Gloucester Road, Hong Kong, or to info@MONITOR-24.com, or to the
        email address you provide to MONITOR-24 during the registration process
        (in your case). Notice shall be deemed given 24 hours after email is
        sent, unless the sending party is notified that the email address is
        invalid. Governing Law and Forum
      </p>
      <p>
        This Agreement shall be governed in all respects and constructured in
        accordance with the laws of the Hong Kong Special Administrative Region
        (HKSAR), China, and you hereby consent and submit to the exclusive
        jurisdiction ofthe competent courts of the HKSAR over all matters
        relating to this Agreement.
      </p>
    </div>
  );
};

export default TermsPage;
