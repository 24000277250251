import React, { useEffect } from "react";
import tableIcon from "../../../assets/img/table-icon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAPIActionJSON } from "../../api/ApiActions";
import { useTranslation } from "react-i18next";

const AgentListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const services = useSelector((state) => state.user.services);
  const { t } = useTranslation();
  const currentTime = new Date();
  const isoString = currentTime.toISOString();
  console.log(isoString);
  useEffect(() => {
    dispatch(
      getAPIActionJSON(
        "getAllService",
        null,
        (e) => {},
        null,
        (e) => {},
        "",
        false
      )
    );
  }, []);
  const handleEdit = (id) => {
    try {
      navigate(`/agent?id=${id}`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="alarms-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="add-agent">
              <div className="form-item">
                <a
                  onClick={() => navigate("/agent")}
                  role="button"
                  className="primary-btn"
                >
                  {t("add-agent")} <i className="far fa-plus"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="alarms-text">
              <h4>{isoString}</h4>
            </div>
            <div className="alarms-table">
              {services.length !== 0 ? (
                <div className="agents-list">
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>{t("name")}</th>
                        <th>{t("url")}</th>
                        <th>{t("validation")}</th>
                        <th>{t("status")}</th>
                        <th>{t("active")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              item.Status === "error"
                                ? "lightcoral"
                                : item.Status === "OK"
                                ? "lightgreen"
                                : item.Status === "unstable"
                                ? "lightorange"
                                : "inherit",
                          }}
                        >
                          <td>{item.Service}</td>
                          <td>
                            {item.Protocol +
                              "://" +
                              item.Host +
                              ":" +
                              item.Port +
                              "/" +
                              item.Path}
                          </td>
                          <td>{item.Validation}</td>
                          <td>{item.Status}</td>
                          <td>{item.Active.toString()}</td>
                          <td>
                            <button
                              onClick={() => handleEdit(item.Serviceid)}
                              className="primary-btn-thin"
                            >
                              {t("edit")} <i className="fal fa-arrow-right"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="argent-description">
                  <div className="arent-item my-5">
                    <div className="alarm-txt fixed-wd">
                      <img src={tableIcon} alt="" />
                      <p>{t("msg-no-agent")}</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="arent-item with-border">
                <div className="argent-content">
                  <p>{t("note-agent-list-1")}</p>
                  <p>{t("note-agent-list-2")}</p>
                  <p>{t("note-agent-list-3")}</p>
                  <p dangerouslySetInnerHTML={{ __html: t('note-agent-list-4') }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="space-x" style={{ height: 450 }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentListPage;
