const initialState = [
    {
        "uid": 0,
        "title": "Free Package",
        "sub": "2 Website/Servers",
        "desc": [
            "1 Website",
            "1 Monitor",
            "10 Minute Frequency",
            "IM Notification"
        ],
        "agents": 2,
        "length": 0,
        "history": 0,
        "sms": 0,
        "price": 10,
        "url": "https://buy.stripe.com/8wM9D8fQO9xXeZ28wM",
        "active": false
    },
    {
        "uid": 1,
        "title": "Starter Monthly",
        "sub": "up to 10 Agents/Probes",
        "desc": [
            "up to 10 Agents",
            "1 Minute Frequency",
            "10 Free SMS",
            " IM Fallback",
            " 1 Month History"
        ],
        "agents": 10,
        "length": 30,
        "history": 0,
        "sms": 0,
        "price": 1,
        "url": "https://buy.stripe.com/28o02ydIG6lL6swdQZ",
        "active": false
    },
    {
        "uid": 2,
        "title": "Starter Yearly",
        "sub": "2 Months Free",
        "desc": [
            "2 Free Months",
            "up to 10 Agents",
            "1 Minute Frequency",
            "10 Free SMS",
            "IM Fallback",
            "1 Month History"
        ],
        "agents": 20,
        "length": 360,
        "history": 0,
        "sms": 0,
        "price": 0.3,
        "url": "https://buy.stripe.com/00g9D80VU39z6sw8wH",
        "active": false
    },
    {
        "uid": 3,
        "title": "Premium Monthly",
        "sub": "50 Agents/Probes",
        "desc": [
            "up to",
            "50 Monitors",
            "30 Second Frequency",
            "100 Free SMS",
            "IM Fall Back",
            "3 Months History"
        ],
        "agents": 50,
        "length": 30,
        "history": 0,
        "sms": 0,
        "price": 0.15,
        "url": "https://buy.stripe.com/4gweXscECdOd9EI00e",
        "active": false
    },
    {
        "uid": 4,
        "title": "Premium Yearly",
        "sub": "2 Months Free",
        "desc": [
            "2 Free Months",
            "up to",
            "50 Monitors",
            "30 Second Frequency",
            "100 Free SMS",
            "IM Fall Back",
            "3 Months History"
        ],
        "agents": 50,
        "length": 360,
        "history": 0,
        "sms": 0,
        "price": 0.15,
        "url": "https://buy.stripe.com/9AQ16CcEC5hH8AE6oD",
        "active": false
    }
];

export default function PlanReducer(state = initialState, action) {
    switch (action.type) {
        case "getPlans.reply":
            return action.data;

        default:
            return state

    }
}