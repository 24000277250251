import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "../assets/css/bootstrap.css";
import "../assets/css/fontawesome.css";
import "../assets/css/all.min.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "../assets/css/nice-select.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { Provider } from "react-redux";
import store from "./app/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </DndProvider>
);
