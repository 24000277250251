import React from "react";
import heroImg from "../../../assets/img/hero-img.png";
import handImg from "../../../assets/img/hand.png";
import quoteTopImg from "../../../assets/img/quote-top.png";
import testing1Image from "../../../assets/img/testi-1.png";
import testing2Image from "../../../assets/img/testi-2.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const StatCard = ({ value, text }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
      const animateValue = (start, end, duration) => {
          let startTimestamp = null;
          const step = (timestamp) => {
              if (!startTimestamp) startTimestamp = timestamp;
              const progress = Math.min((timestamp - startTimestamp) / duration, 1);
              setCount(Math.floor(progress * (end - start) + start));
              if (progress < 1) {
                  window.requestAnimationFrame(step);
              }
          };
          window.requestAnimationFrame(step);
      };

      animateValue(0, value, 2000);
  }, [value]);
  return (
    <div className="statcard">
        <h1>{count.toLocaleString()} <span className="statplus">+</span></h1>
        <p>{text}</p>
    </div>
);
}
const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="homepage">
      <div className="slideMenu">
        <div className="menu_close">
          <i className="fal fa-times"></i>
        </div>
        <div className="menu_list">
          <ul>
            <li>
              <a className="index.html" href="/">
                {t("home")}
              </a>
            </li>
            <li>
              <a href="/about">{t("learn-more")}</a>
            </li>
            <li>
              <a href="/pricing">{t("pricing")}</a>
            </li>
            <li>
              <a href="/support">{t("support")}</a>
            </li>
          </ul>
        </div>
        <div className="header-right-optino">
          <p>
            business@futurembrace.com | <a href="index.html">{t("logout")}</a>
          </p>
        </div>
      </div>

      <div className="hero-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-top-wrapper">
                <h2>{t("slogan-1")}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-bottom-wrapper">
                <div className="row align-items-center">
                  <div className="col-lg-56 col-md-5">
                    <div className="hero-left">
                      <img src={heroImg} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 margin-top" >
                    <div className="hero-right">
                      <img src={handImg} className="hand_img" alt="" />
                      <h2>{t("alerts")}</h2>
                      <h3> {t("tagline")} </h3>
                      <div className="large_btn">
                        <a
                          onClick={() => navigate("/register")}
                          className="primary-btn"
                        >
                          {t("signup-for-free")}
                          <i className="far fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="business-wrapper">
                <h2>{t("business")}</h2>
                <div className="business-content">
                  <p>{t("business-text-1")}</p>
                  <p>{t("business-text-2")}</p>
                  <ul className="buss-list">
                    <li>{t("business-text-2.1")}</li>
                    <li>{t("business-text-2.2")}</li>
                    <li>{t("business-text-2.3")}</li>
                    <li>{t("business-text-2.4")}</li>                    
                  </ul>
                  <p>{t("business-text-3")}</p>
                  <ul className="buss-list">
                    <li>{t("business-text-3.1")}</li>
                    <li>{t("business-text-3.2")}</li>
                    <li>{t("business-text-3.3")}</li>
                    <li>{t("business-text-3.4")}</li>
                  </ul>
                  <p>{t("business-text-4")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="area-title title_phone">
                <h2>{t("use-cases")}</h2>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="testimonial-item">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 order-last order-md-first">
                    <div className="area-title title_desk">
                      <h2>{t("examples")}</h2>
                    </div>
                    <div className="testimonial-content">
                      <div className="quote-top">
                        <img src={quoteTopImg} alt="" />
                      </div>
                      <p>{t("example-1")}</p>
                      <div className="quote-bottom">
                        <img src={quoteTopImg} alt="" />
                      </div>
                      <span>{t("example-1-note")}</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 order-first order-md-last">
                    <div className="testimonial-thumbnail">
                      <div className="testimonial-box">
                        <img src={testing1Image} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div className="col-lg-12">
              <div className="testimonial-item">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="testimonial-thumbnail">
                      <div className="testimonial-box">
                        <img src={testing2Image} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="testimonial-content">
                      <div className="quote-top">
                        <img src={quoteTopImg} alt="" />
                      </div>
                      <p>{t("example-2")}</p>
                      <div className="quote-bottom">
                        <img src={quoteTopImg} alt="" />
                      </div>
                      <span>{t("example-2-note")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          <div className="stat">
              <div className="statcontainer">
                  <div className="statcard">
                      <h1>18K <span className="statplus">+</span></h1>
                      <p>Total Systems Monitored</p>
                  </div>
                  <div className="statcard">
                      <h1>12K <span className="statplus">+</span></h1>
                      <p>Total Relationships</p>
                      <hr/>
                      <h1>26M <span className="statplus">+</span></h1>
                      <p>Total Clients' Customers</p>
                  </div>
                  <div className="statcard">
                      <h1>1M <span className="statplus">+</span></h1>
                      <p>Total Enquiries</p>
                      <hr/>
                      <h1>5K <span className="statplus">+</span></h1>
                      <p>Total Free Memberships</p>
                  </div>
              </div>
          </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;