import React, { useState } from "react";
import "./IconDropdown.css";
import { useDispatch, useSelector } from "react-redux";

const IconDropdown = () => {
  const [selectedCountry, setSelectedCountry] = useState("en");
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.user.language);
  const handleSelectChange = (event) => {
    setSelectedCountry(event.target.value);
    const selectedLanguage = event.target.value;
    dispatch({ type: "changeLanguage", data: selectedLanguage });
  };

  return (
    <select
      id="country-select"
      value={currentLanguage}
      onChange={handleSelectChange}
      style={{
        backgroundImage: `url(/flags/${currentLanguage}.svg)`,
      }}
    >
      <option value="en" data-flag="flags/en.svg">
        English
      </option>
      <option value="hk" data-flag="flags/hk.svg">
        中國香港
      </option>
      <option value="cn" data-flag="flags/cn.svg">
        中国
      </option>
      <option value="tw" data-flag="flags/tw.svg">
        台灣
      </option>
      <option value="jp" data-flag="flags/jp.svg">
        にほん (日本)
      </option>
      <option value="kn" data-flag="flags/kn.svg">
        북한
      </option>
      <option value="ks" data-flag="flags/ks.svg">
        대한민국
      </option>
      <option value="es" data-flag="flags/es.svg">
        España
      </option>
      <option value="fr" data-flag="flags/fr.svg">
        Français
      </option>
      <option value="vi" data-flag="flags/vi.svg">
        Tiếng Việt
      </option>
      {/* Add more options as needed */}
    </select>
  );
};

export default IconDropdown;
