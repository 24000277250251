import React from "react";
import { useTranslation } from "react-i18next";

const FaqPage = () => {
  const { t } = useTranslation();
  return (
    <div className="announcements-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="area-title">
              <h2>{t("faq-full-text")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="announcements-wrapper">
              <div className="announcements-timeline">
                <div className="announcement-item">
                  <div className="announcement-step">
                    <span>01</span>
                  </div>
                  <div className="announcement-text">
                    <h2>{t("notifications")}</h2>
                    <h4>{t("notification-question-1")}</h4>
                    <p>{t("notification-answer-1")}</p>
                    <ol>
                      <li>{t("notification-answer-1.1")}</li>
                      <li>{t("notification-answer-1.2")}</li>
                      <li>{t("notification-answer-1.3")}</li>
                      <li>{t("notification-answer-1.4")}</li>
                      <li>{t("notification-answer-1.5")}</li>
                    </ol>
                  </div>
                </div>
                <div className="announcement-item">
                  <div className="announcement-step">
                    <span>02</span>
                  </div>
                  <div className="announcement-text">
                    <h4>{t("notification-question-2")}</h4>
                    <p>{t("notification-answer-2.1")}</p>
                    <p>{t("notification-answer-2.2")}</p>
                    <p>{t("notification-answer-2.3")}</p>
                  </div>
                </div>
                <div className="announcement-item">
                  <div className="announcement-step">
                    <span>03</span>
                  </div>
                  <div className="announcement-text">
                    <h2>{t("agents")}</h2>
                    <h4>{t("agents-question-1")}</h4>
                    <p>{t("agents-answer-1")}</p>
                  </div>
                </div>
                <div className="announcement-item">
                  <div className="announcement-step">
                    <span>04</span>
                  </div>
                  <div className="announcement-text">
                    <h4>{t("agents-question-2")}</h4>
                    <p>{t("agents-answer-2.1")}</p>
                    <h3>{t("for-window")}</h3>
                    <p>{t("agents-answer-2.2")}</p>
                    <h3>{t("for-unix")}</h3>
                    <p>{t("agents-answer-2.3")} </p>
                  </div>
                </div>
                <div className="announcement-item">
                  <div className="announcement-step">
                    <span>05</span>
                  </div>
                  <div className="announcement-text">
                    <h2>{t("subscriptions")}</h2>
                    <h4>{t("subscriptions-question-1")}</h4>
                    <p>{t("subscriptions-answer-1.1")}</p>
                    <p>{t("subscriptions-answer-1.2")}</p>
                  </div>
                </div>
                <div className="announcement-item">
                  <div className="announcement-step">
                    <span>06</span>
                  </div>
                  <div className="announcement-text">
                    <h2>{t("telegram-id")}</h2>
                    <h4>{t("telegram-question-1")}</h4>
                    <p>{t("telegram-answer-1")}</p>
                    <p>
                      <ol>
                        <li>{t("telegram-answer-1.1")}</li>
                        <li>{t("telegram-answer-1.2")}</li>
                        <li>{t("telegram-answer-1.3")}</li>
                        <li>{t("telegram-answer-1.4")}</li>
                        <li>{t("telegram-answer-1.5")}</li>
                      </ol>
                    </p>
                  </div>
                </div>
                <div className="announcement-item">
                  <div className="announcement-step">
                    <span>07</span>
                  </div>
                  <div className="announcement-text">
                    <h2>{t("other-msg-systems")}</h2>
                    <h4>{t("msg-question-1")}</h4>
                    <p>{t("msg-answer-1")}</p>
                    <p>
                      {t("msg-answer-1.1")}
                      <ol>
                        <li>{t("slack")}</li>
                        <li>{t("whatsapp")}</li>
                        <li>{t("wechat")}</li>
                        <li>{t("signal")}</li>
                        <li>{t("discord")}</li>
                        <li>{t("skype")}</li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <div className="older-announcements">
              <div className="older-btn">
                <a href="" className="primary-btn large_btn">
                  Older announcements <i className="fal fa-angle-down"></i>
                </a>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-lg-12">
            <div className="support-wrap">
              <div className="area-title mb-0">
                <h2>{t("support")}</h2>
              </div>
              <div className="support-note">
                <p>
                  {t("support-text-1")}
                  <a href="mailto:support@monitor-24.com">
                    support@monitor-24.com
                  </a>
                  {t("support-text-2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
