import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  convertFormat,
  getKeyByValue,
  reverseTransformArray,
  transformArray,
} from "../../utils/functions/commonFunction";
import { useDrag } from "react-dnd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { getAPIActionJSON } from "../../api/ApiActions";
import { toast } from "react-toastify";
import {
  errorStyle,
  languagueConvert,
  successStyle,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DraggableItem = SortableElement(
  ({ id, label, placeholder, value, isEnable, handleInputChange }) => {
    const [, drag] = useDrag({
      type: "COMPONENT",
      item: { id },
    });
    return (
      <div style={{ marginBottom: 25 }} className="row align-items-center">
        <div className="col-lg-3 col-md-3">
          <label htmlFor="">{label}</label>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="field-single">
            <input
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={(e) => handleInputChange(e, id, "value")}
            />
          </div>
        </div>
        <div className="ck-itm col-lg-1 col-md-1">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isEnable}
              onChange={(e) => handleInputChange(e, id, "isEnable")}
              id="mobile-ck-101"
            />
            <label className="form-check-label" htmlFor="ck-101"></label>
          </div>
        </div>
      </div>
    );
  }
);
const DraggableList = SortableContainer(({ items, handleInputChange }) => {
  if (!items) return null;
  console.log(items);
  return (
    <div>
      {items.map((item, index) => (
        <DraggableItem
          key={item.id}
          index={index}
          handleInputChange={handleInputChange}
          {...item}
        />
      ))}
    </div>
  );
});
const AccountPage = () => {
  const { t } = useTranslation();
  const [confirmText, setConfirmText] = useState(false);
  const navigate = useNavigate();
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [selectedLanguage, setSelectedLanguage] = useState(
    getKeyByValue(languagueConvert, userData?.language)
  ); // Default to English
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([
    {
      id: 1,
      type: "email",
      label: t("notification-email"),
      placeholder: "support@mycompany.com",
      value: "",
      isEnable: false,
    },
    {
      id: 2,
      type: "sms",
      label: t("mobile-number"),
      placeholder: "(852) 123242142566",
      value: "",
      isEnable: false,
    },
    {
      id: 3,
      type: "telegram",
      label: t("telegram-username"),
      placeholder: "@username",
      value: "",
      isEnable: false,
    },

    {
      id: 4,
      type: "discord",
      label: t("discord-server"),
      placeholder: "server/channel",
      value: "",
      isEnable: false,
    },

    {
      id: 5,
      type: "slack",
      label: t("slack-server"),
      placeholder: "server/channel",
      value: "",
      isEnable: false,
    },

    {
      id: 6,
      type: "whatsapp",
      label: t("whatsapp"),
      placeholder: "(84) 966830889",
      value: "",
      isEnable: false,
    },
  ]);
  const handleSave = () => {
    try {
      const Messengers = transformArray(items);
      const data = {
        ...userData,
        Messengers: Messengers ? Messengers : userData.Messengers,
        language: languagueConvert[selectedLanguage],
      };
      dispatch(
        getAPIActionJSON(
          "updateUser",
          data,
          (e) => handleError(e),
          null,
          (e) => handleSuccess(e)
        )
      );
    } catch (error) {}
  };
  const handleDeleteAccount = () => {
    if (confirmText) {
      try {
        dispatch(
          getAPIActionJSON(
            "deleteAccount",
            null,
            (e) => handleError(e),
            null,
            (e) => handleSuccess(e, "deleteAccount")
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    setConfirmText(!confirmText);
  };
  const handleChangePassword = () => {
    try {
      dispatch(
        getAPIActionJSON(
          "forgotPassword",
          { email: userData.email },
          (e) => handleError(e),
          null,
          (e) => handleSuccess(e, "forgotpw")
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleError = (response) => {
    toast.dismiss();
    toastId.current = toast.error(t("toast-request-fail"), errorStyle);
  };
  const handleSuccess = (response, type) => {
    if (response.success) {
      toast.dismiss();
      if (type === "deleteAccount") {
        dispatch({ type: "logOut" });
        toastId.current = toast.success(
          t("toast-account-deleted"),
          successStyle
        );
        navigate("/login");
        return;
      }
      if (type === "forgotpw") {
        toastId.current = toast.success(t("toast-forgot-pw"), successStyle);
        return;
      }
      toastId.current = toast.success(t("toast-updated"), successStyle);
    } else handleError();
  };
  const handleLanguageChange = (value, language) => {
    dispatch({ type: "changeLanguage", data: language });
    setSelectedLanguage(value);
    setIsOpen(false);
  };
  const handleInputChange = (event, id, fieldName) => {
    const newValue =
      fieldName === "value" ? event.target.value : event.target.checked;
    updateField(id, fieldName, newValue);
  };
  const updateField = (itemId, fieldName, newValue) => {
    setItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, [fieldName]: newValue };
        }
        return item;
      });
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      const [movedItem] = newItems.splice(oldIndex, 1);
      newItems.splice(newIndex, 0, movedItem);
      return newItems;
    });
  };
  const toggleLanguageOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const newData = reverseTransformArray(items, userData?.Messengers);

    newData && setItems(newData);
  }, [userData]);
  return (
    <div className="account-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="area-title">
              <h2>{t("account-profile")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="account-wrapper warpper_account">
              <div className="account-wrap">
                <div className="appearance-single">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3">
                      <label htmlFor="">{t("active-since")}</label>
                    </div>
                    <div className="col-lg-9 col-md-9">
                      <div className="appreance-txt">
                        <p>{convertFormat(userData?.created)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appearance-single">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3">
                      <label htmlFor="">{t("last-login")}</label>
                    </div>
                    <div className="col-lg-9 col-md-9">
                      <div className="appreance-txt">
                        <p>{convertFormat(userData?.end)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appearance-single">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3">
                      <label htmlFor="">ID</label>
                    </div>
                    <div className="col-lg-9 col-md-9">
                      <div className="appreance-txt">
                        <p>{userData?.accountid}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appearance-single">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3">
                      <label htmlFor="">{t("email-address")}</label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="field-single">
                        <input
                          disabled
                          type="text"
                          placeholder="Email"
                          value={userData?.email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appearance-single">
                  <div style={{ marginBottom: 25 }}>
                    {t("notification-order-note")}
                  </div>
                  <DraggableList
                    items={items}
                    handleInputChange={handleInputChange}
                    onSortEnd={onSortEnd}
                  />
                </div>

                <div className="appearance-single">
                  <div
                    onClick={toggleLanguageOpen}
                    className="row align-items-center"
                  >
                    <div className="col-lg-3 col-md-3">
                      <label htmlFor="">{t("language-preference")}</label>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="field-single span-box common-select">
                        <div
                          className={`nice-select ${isOpen ? "open" : ""}`}
                          tabIndex="0"
                        >
                          <span className="current">{selectedLanguage}</span>
                          <ul className="list">
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "English [UK,US,etc]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="en"
                              className="option selected focus"
                            >
                              English [UK,US,etc]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "中国 [Peoples Republic of China]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="cn"
                              className="option"
                            >
                              中国 [Peoples Republic of China]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "中國香港 [HKSAR, China]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="hk"
                              className="option"
                            >
                              中國香港 [HKSAR, China]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "台灣 [Taiwan]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="tw"
                              className="option"
                            >
                              台灣 [Taiwan]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "にほん (日本) [Japan]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="jp"
                              className="option"
                            >
                              にほん (日本) [Japan]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "북한(Bukhan) [North Korea]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="kn"
                              className="option"
                            >
                              북한(Bukhan) [North Korea]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "대한민국(Daehan Minguk) [South Korea]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="ks"
                              className="option"
                            >
                              대한민국(Daehan Minguk) [South Korea]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "España [Spain]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="es"
                              className="option"
                            >
                              España [Spain]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "Français [France]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="fr"
                              className="option"
                            >
                              Français [France]
                            </li>
                            <li
                              onClick={(e) =>
                                handleLanguageChange(
                                  "Tiếng Việt [Vietnam]",
                                  e.target.getAttribute("data-value")
                                )
                              }
                              data-value="vi"
                              className="option"
                            >
                              Tiếng Việt [Vietnam]
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appearance-single">
                  <div className="row align-items-center">
                    <div className="col-lg-4 col-md-3">
                      <button onClick={handleSave} className="primary-btn">
                        {t("save")} <i className="fal fa-arrow-right"></i>
                      </button>
                    </div>
                    <div className="col-lg-4 col-md-3">
                      <button
                        onClick={handleChangePassword}
                        className="primary-btn"
                      >
                        {t("password")} <i className="fal fa-arrow-right"></i>
                      </button>
                    </div>
                    <div className="col-lg-4 col-md-3">
                      <button
                        onClick={handleDeleteAccount}
                        className={`primary-btn ${confirmText ? "delete" : ""}`}
                      >
                        {confirmText ? t("are-you-sure") : t("delete-account")}{" "}
                        <i className="fal fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
