import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAPIActionJSON } from "../../api/ApiActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { errorStyle, successStyle } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { emailRegex } from "../../utils/functions/commonFunction";

const LoginPage = () => {
  const { t } = useTranslation();
  const toastId = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const emailInputRef = useRef(null);

  useEffect(() => {
    // Focus the email input field when the component mounts
    emailInputRef.current.focus();
  }, []);
  useEffect(() => {
    setEmailError("");
  }, [email]);
  useEffect(() => {
    setPasswordError("");
  }, [password]);
  const onValidation = () => {
    try {
      let success = true;
      if (!email) {
        setEmailError(t("error-empty-field"));
        success = false;
      }
      if (!emailRegex.test(email) && email) {
        setEmailError(t("login-email-invalid-format"));
        success = false;
      }
      if (!password) {
        setPasswordError(t("error-empty-field"));
        success = false;
      }
      return success;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      handleLogin();
    }
  };
  const handleLogin = () => {
    try {
      const success = onValidation();
      if (!success) return;
      const data = {
        email: email,
        password: password,
      };
      dispatch(
        getAPIActionJSON(
          "login",
          data,
          (e) => handleError(e),
          null,
          (e) => handleSuccess(e),
          ""
        )
      );
      return;
    } catch (error) {
      console.log(error);
    }
  };
  const handleError = (response) => {
    toast.dismiss();
    toastId.current = toast.error(
      "Login Fail ! Please check your information",
      errorStyle
    );
  };
  const handleSuccess = (response) => {
    if (response.success) {
      if (isChecked) {
        localStorage.setItem("user", JSON.stringify(response.Data));
      }
      toast.dismiss();
      toastId.current = toast.success("🦄 Login successful !", successStyle);
      navigate("/agent-list");
    }
  };
  return (
    <div className="login-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="area-title">
              <h2>{t("login").toUpperCase()}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="login-form">
              <form action="">
                <div className="form-item">
                  <label for="">{t("email")}</label>
                  <div className="field-single">
                    <input
                      onKeyDown={handleKeyDown}
                      type="text"
                      placeholder={t("email")}
                      className={emailError ? "error" : ""}
                      value={email}
                      ref={emailInputRef}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {emailError ? (
                    <div style={{ padding: 5, color: "red", fontSize: 15 }}>
                      {emailError}
                    </div>
                  ) : null}
                </div>
                <label for="">{t("password")}</label>
                <div className="form-item">
                  <div className="field-single">
                    <input
                      onKeyDown={handleKeyDown}
                      type="password"
                      placeholder={t("password")}
                      className={passwordError ? "error" : ""}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {passwordError ? (
                    <div style={{ padding: 5, color: "red", fontSize: 15 }}>
                      {passwordError}
                    </div>
                  ) : null}
                </div>

                <div className="form-item1">
                  <div className="signup-checkbox" style={{ paddingTop: 12.5 }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isChecked}
                        id="flexCheckDefault"
                        onChange={(e) => setIsChecked(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <p>{t("remember-me")}</p>
                      </label>
                    </div>
                  </div>
                  <div className="forgot-password-link">
                    <p>
                      <a onClick={() => navigate("/reset-password")}>
                        {t("forgot-pw")}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="form-item">
                  <a
                    onClick={handleLogin}
                    tabIndex="0"
                    role="button"
                    className="primary-btn"
                  >
                    {t("login")} <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </form>
              <div className="another-option">
                <h5>{t("or")}</h5>
                <a
                  onClick={() => navigate("/register")}
                  className="secondary-btn"
                >
                  {t("create-new-account")}{" "}
                  <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
