import React from "react";
import { useTranslation } from "react-i18next";

const SupportPage = () => {
  const { t } = useTranslation();
  return (
    <div className="login-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="area-title">
              <h2>{t("support")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="login-form form__two">
              <form action="">
                <div className="form-item">
                  <label for="">{t("email-address")}</label>
                  <div className="field-single">
                    <input type="text" placeholder="" />
                  </div>
                </div>
                <div className="form-item">
                  <label for="">{t("subject")}</label>
                  <div className="field-single">
                    <input type="text" placeholder="" />
                  </div>
                </div>
                <div className="form-item">
                  <label for="">{t("message")}</label>
                  <div className="field-single">
                    <textarea name="" id="" cols="30" rows="10"></textarea>
                  </div>
                </div>
                <div className="form-item">
                  <button type="submit" className="primary-btn">
                    {t("send")} <i className="far fa-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
