import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPIActionJSON } from "../../api/ApiActions";
import PlanInfo from "../../components/molecules/planInfo";
import { useTranslation } from "react-i18next";

const PricingPage = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.plan);
  const { t } = useTranslation();

  const renderPlan = plan?.map((data) => {
    if (isMonthly && [10, 11, 12].includes(data.uid)) return null;
    if (!isMonthly && [0, 1, 2].includes(data.uid)) return null;

    return <PlanInfo data={data} />;
  });
  useEffect(() => {
    dispatch(getAPIActionJSON("getPlans"));
  }, []);
  return (
    <div className="pricing-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="area-title">
              <h2>{t("text-pricing-title")}</h2>
              <p dangerouslySetInnerHTML={{ __html: t("text-subtitle") }}></p>
            </div>
          </div>
        </div>
        <div className="toggleContainer">
          <div
            onClick={() => setIsMonthly(true)}
            className={isMonthly ? "toggleButton" : "toggleButton1"}
          >
            {t("tab-monthly")}
          </div>
          <div
            onClick={() => setIsMonthly(false)}
            className={isMonthly ? "toggleButton1" : "toggleButton"}
          >
            {t("tab-yearly")}
          </div>
        </div>
        <div className="row">{renderPlan}</div>

        <div className="row">
          <div className="col-lg-12">
            <div className="price-note-wrapper">
              <p>{t("text-options")}</p>
              <p>{t("text-support")}</p>
              <p
                dangerouslySetInnerHTML={{ __html: t("text-guide-billing") }}
              ></p>
              <p dangerouslySetInnerHTML={{ __html: t("text-contact") }}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
