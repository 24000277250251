import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationEN from '../../assets/languages/en.json';
import translationVI from '../../assets/languages/vi.json';
import translationCN from '../../assets/languages/cn.json';
import translationES from '../../assets/languages/es.json';
import translationHK from '../../assets/languages/hk.json';
import translationJP from '../../assets/languages/jp.json';
import translationKN from '../../assets/languages/kn.json';
import translationKS from '../../assets/languages/ks.json';
import translationTW from '../../assets/languages/tw.json';
import translationFR from '../../assets/languages/fr.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    vi: {
        translation: translationVI
    },
    cn: {
        translation: translationCN
    },
    es: {
        translation: translationES
    },
    hk: {
        translation: translationHK
    },
    jp: {
        translation: translationJP
    },
    kn: {
        translation: translationKN
    },
    ks: {
        translation: translationKS
    },
    tw: {
        translation: translationTW
    },
    fr: {
        translation: translationFR
    },
};

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;