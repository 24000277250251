import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/organisms/header";
import Footer from "./components/organisms/footer";
import HomePage from "./pages/home";
import SideMenu from "./components/organisms/sideMenu";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import LearnMorePage from "./pages/learn-more";
import PricingPage from "./pages/pricing";
import SupportPage from "./pages/support";
import React, { useEffect } from "react";
import FaqPage from "./pages/faq";
import PolicyPage from "./pages/policy";
import ScrollToTop from "./utils/hooks/ScrollToTop";
import LoadingOverlay from "./components/molecules/loader";
import ActivationPage from "./pages/activate";
import AgentListPage from "./pages/agent-list";
import AccountPage from "./pages/account";
import LoggedHeader from "./components/organisms/logged-header";
import ResetPasswordPage from "./pages/reset-pw";
import TermsPage from "./pages/terms";
import AgentPage from "./pages/agent";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation/i18n";

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get("lang");
  const loadingAPI = useSelector((state) => state.app.loading);
  const state = useSelector((state) => state);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const selectedLanguage = useSelector((state) => state.user.language);
  const dispatch = useDispatch();
  if (
    (window.location.hostname === "127.0.0.1" ||
      window.location.hostname === "localhost") &&
    window.location.port === "5173"
  ) {
    console.log("current state", state);
  }
  useEffect(() => {
    if (typeof selectedLanguage === "string") {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);
  useEffect(() => {
    if (langParam) {
      i18n.changeLanguage(langParam.toLowerCase());
      dispatch({ type: "changeLanguage", data: langParam.toLowerCase() });
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <div>
            {isLoggedIn ? <LoggedHeader /> : <Header />}
            <SideMenu />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/about" element={<LearnMorePage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/support" element={<SupportPage />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/policy" element={<PolicyPage />} />
              <Route path="/activate" element={<ActivationPage />} />
              <Route path="/agent-list" element={<AgentListPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/agent" element={<AgentPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/terms" element={<TermsPage />} />
            </Routes>
            <Footer />
          </div>
        </I18nextProvider>
      </BrowserRouter>
      <LoadingOverlay loading={loadingAPI} />
    </>
  );
}

export default App;
