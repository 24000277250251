
const previousUser = localStorage.getItem("user")
const previousLanguage = localStorage.getItem("language")
const previousUserObject = JSON.parse(previousUser)
const initialState = {
    email: previousUserObject?.email ? previousUserObject?.email : "",
    isLoggedIn: previousUserObject ? true : false,
    language: previousLanguage ? previousLanguage : (previousUserObject ? previousUserObject.language : "en"),
    userData: previousUserObject ? previousUserObject : {},
    services: []
}

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        case "tokenExpired":
            localStorage.removeItem("user")
            localStorage.removeItem("x-api-key")
            alert('Session Expired ! Please login again to continue !')
            window.location.href = 'https://monitor-24.com/login';
            return {
                email: "",
                isLoggedIn: false,
                language: "en",
                userData: {},
                services: []
            }
        case "saveEmail":
            return {
                ...state,
                email: action.data
            }
        case "logOut":
            localStorage.clear();
            return {
                ...state,
                email: "",
                isLoggedIn: false,
                userData: {}
            }
        case "login.reply":
            if (action.data.success) {
                // localStorage.setItem('username', action.data.data.username)
                // localStorage.setItem('role', action.data.data.role)
                return {
                    ...state,
                    isLoggedIn: action.data.success,
                    email: action.data.Data.email,
                    language: action.data.Data.language,
                    userData: action.data.Data
                }
            }
        case "activate.reply":
            if (action.data.success) {
                return {
                    ...state,
                    isLoggedIn: action.data.success,
                    email: action.data.Data.email,
                    language: action.data.Data.language,
                    userData: action.data.Data
                }
            }
        case "changeUserID":
            return {
                ...state,
                userID: action.data
            }
        case "getUser.reply":
            return {
                ...state,
                userData: action.data,
                language: action.data.language
            }
        case "updateUser.reply":
            return {
                ...state,
                userData: action.data.Data,
                language: action.data.Data.language
            }
        case "getAllService.reply":
            if (action.data.success) {
                return {
                    ...state,
                    services: action.data.data
                }
            }
        case "changeLanguage": {
            localStorage.setItem('language', action.data)
            return {
                ...state,
                language: action.data
            }
        }
        default:
            return state

    }
}

