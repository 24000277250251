import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAPIActionJSON } from "../../api/ApiActions";
import { toast } from "react-toastify";
import { errorStyle, successStyle } from "../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AgentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isOpen, setIsOpen] = useState(false);
  const [isValidationOpen, setIsValidationOpen] = useState(false);
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const [data, setData] = useState({
    Accountid: userData.accountid,
    Service: "",
    Protocol: "http",
    Host: "",
    Port: "80",
    Path: "",
    Retries: 1,
    Timeout: "30",
    Validation: "none",
    List: "",
    All: "0",
    Active: true,
    Notification: "Major",
    Status: "",
    agent: "out",
  });

  const handleCheckboxChange = (e) => {
    setData({
      ...data,
      Active: e.target.checked,
    });
  };
  const handleDeleteService = () => {
    try {
      dispatch(
        getAPIActionJSON(
          "deleteService",
          { serviceid: parseInt(id) },
          (e) => handleError(e, "delete"),
          null,
          (e) => handleSuccess(e, "delete")
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleTestService = () => {
    try {
      dispatch(
        getAPIActionJSON(
          "testService",
          data,
          (e) => handleError(e, "test"),
          null,
          (e) => handleSuccess(e, "test")
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleCreateService = () => {
    try {
      dispatch(
        getAPIActionJSON(
          "createService",
          data,
          (e) => handleError(e, "create"),
          null,
          (e) => handleSuccess(e, "create")
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleError = (response, type) => {
    toast.dismiss();
    if (type === "test") {
      toastId.current = toast.error(
        response.message
          ? t("failed") + response.message
          : t("toast-test-agent-fail"),
        errorStyle
      );
      return;
    }
    if (type === "delete") {
      toastId.current = toast.error(
        response.message
          ? t("failed") + response.message
          : t("toast-delete-agent-fail"),
        errorStyle
      );
      return;
    }
    toastId.current = toast.error(
      response.message
        ? t("failed") + response.message
        : t("toast-create-agent-failt"),
      errorStyle
    );
  };
  const handleSuccess = (response, type) => {
    if (response.success) {
      toast.dismiss();
      if (type === "test") {
        toastId.current = toast.success(
          t("toast-test-agent-success") + response.message,
          successStyle
        );
        return;
      }
      if (type === "delete") {
        toastId.current = toast.success(
          t("toast-delete-agent-success") + response.message,
          successStyle
        );
        navigate("/agent-list");
        return;
      }
      toastId.current = toast.success(
        t("toast-create-agent-success"),
        successStyle
      );
      navigate("/agent-list");
    } else handleError(response, type);
  };

  const handleGetError = (response) => {
    toast.dismiss();
    toastId.current = toast.error(
      response.message
        ? t("failed") + response.message
        : t("toast-unknown-error"),
      errorStyle
    );
    navigate("/agent-list");
  };
  const handleGetSuccess = (response) => {
    if (response.success) {
      setData(response.data[0]);
      return;
    }
    handleGetError(response);
  };
  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  const toggleValidationList = () => {
    setIsValidationOpen(!isValidationOpen);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        getAPIActionJSON(
          "getService",
          { serviceid: parseInt(id) },
          (e) => handleGetError(e),
          null,
          (e) => handleGetSuccess(e),
          ""
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="add-agent-area">
      <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">{t("home")}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/agent-list">{t("agents")}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("edit-agent")}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="agent-info-wrapper">
              <div className="agent-note">
                <p>{t("agent-guide")}</p>
              </div>
              <div className="add-agent-option">
                <div className="option-one">
                  <div className="link-option" onClick={toggleList}>
                    <div className="common-select">
                      <div
                        className={`nice-select ${isOpen ? "open" : ""}`}
                        tabIndex="0"
                      >
                        <span className="current">{data.Protocol}</span>
                        <ul className="list">
                          <li
                            onClick={() =>
                              setData({
                                ...data,
                                Protocol: "http",
                                Port: "80",
                              })
                            }
                            data-value=""
                            className="option selected focus"
                          >
                            http
                          </li>
                          <li
                            onClick={() =>
                              setData({
                                ...data,
                                Protocol: "https",
                                Port: "443",
                              })
                            }
                            data-value=""
                            className="option"
                          >
                            https
                          </li>
                          <li
                            onClick={() =>
                              setData({
                                ...data,
                                Protocol: "ping",
                                Port: "ICMP",
                              })
                            }
                            data-value=""
                            className="option"
                          >
                            ping
                          </li>
                          <li
                            onClick={() =>
                              setData({
                                ...data,
                                Protocol: "port",
                                Port: "1000",
                              })
                            }
                            data-value=""
                            className="option"
                          >
                            port
                          </li>
                          <li
                            onClick={() =>
                              setData({ ...data, Protocol: "ssh", Port: "22" })
                            }
                            data-value=""
                            className="option"
                          >
                            ssh
                          </li>
                          <li
                            onClick={() =>
                              setData({ ...data, Protocol: "ftp", Port: "21" })
                            }
                            data-value=""
                            className="option"
                          >
                            ftp
                          </li>
                          <li
                            onClick={() =>
                              setData({ ...data, Protocol: "sftp", Port: "22" })
                            }
                            data-value=""
                            className="option"
                          >
                            sftp
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="next-option">
                    <span>://</span>
                  </div>
                  <div className="option-field">
                    <input
                      value={data.Host}
                      onChange={(e) =>
                        setData({ ...data, Host: e.target.value })
                      }
                      type="text"
                      name=""
                      id=""
                      placeholder="host"
                    />
                  </div>
                  <div className="next-option">
                    <span>:</span>
                  </div>
                  <div className="option-field">
                    <input
                      value={data.Port}
                      disabled={data.Protocol === "ping" ?? true}
                      onChange={(e) =>
                        setData({ ...data, Port: e.target.value })
                      }
                      type="text"
                      name=""
                      id=""
                      placeholder="80"
                    />
                  </div>
                  <div className="next-option">
                    <span>/</span>
                  </div>
                  <div className="option-field">
                    <input
                      value={data.Path}
                      onChange={(e) =>
                        setData({ ...data, Path: e.target.value })
                      }
                      type="text"
                      name=""
                      id=""
                      placeholder="path"
                    />
                  </div>
                </div>
                {data.Protocol === "sftp" || data.Protocol === "ftp" ? (
                  <>
                    <div className="option-two">
                      <label htmlFor="">{t("username")}:</label>
                      <div className="option-field">
                        <input
                          // value={data.Service}
                          // onChange={(e) =>
                          //   setData({ ...data, Service: e.target.value })
                          // }
                          type="text"
                          name=""
                          id=""
                          placeholder={t("username")}
                        />
                      </div>
                    </div>
                    <div className="option-two">
                      <label htmlFor="">{t("password")}:</label>
                      <div className="option-field">
                        <input
                          // value={data.Service}
                          // onChange={(e) =>
                          //   setData({ ...data, Service: e.target.value })
                          // }
                          type="text"
                          name=""
                          id=""
                          placeholder={t("password")}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="option-two">
                  <label htmlFor="">{t("name")}:</label>
                  <div className="option-field">
                    <input
                      value={data.Service}
                      onChange={(e) =>
                        setData({ ...data, Service: e.target.value })
                      }
                      type="text"
                      name=""
                      id=""
                      placeholder={t("placeholder-name")}
                    />
                  </div>
                </div>
                <div className="option-two">
                  <label htmlFor="">{t("retries")}?:</label>
                  <div className="option-field">
                    <input
                      value={data.Retries}
                      onChange={(e) =>
                        setData({ ...data, Retries: parseInt(e.target.value) })
                      }
                      type="number"
                      name=""
                      id=""
                      placeholder="3"
                    />
                  </div>
                </div>
                <div className="option-two">
                  <label htmlFor="">{t("timeout")}?:</label>
                  <div className="option-field">
                    <input
                      value={data.Timeout}
                      onChange={(e) =>
                        setData({ ...data, Timeout: e.target.value })
                      }
                      type="number"
                      name=""
                      id=""
                      placeholder="30"
                    />
                  </div>
                  <p>{t("timeout")}?:</p>
                </div>
                <div className="signup-checkbox">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={data.Active}
                      checked={data.Active}
                      id="flexCheckDefault"
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      <p>{t("activate")}</p>
                    </label>
                  </div>
                </div>
                <div className="row align-items-start">
                  <div className="col-lg-3 col-md-3">
                    <div
                      className="validation-select"
                      onClick={toggleValidationList}
                    >
                      <div className="common-select">
                        <div
                          className={`nice-select ${
                            isValidationOpen ? "open" : ""
                          }`}
                          tabIndex="0"
                        >
                          <span className="current">
                            {data.Validation.toLowerCase() === "none"
                              ? t("basic-validation")
                              : data.Validation}
                          </span>
                          <ul className="list">
                            <li
                              onClick={() =>
                                setData({ ...data, Validation: "none" })
                              }
                              data-value=""
                              className="option selected"
                            >
                              {t("basic-validation")}
                            </li>
                            <li
                              onClick={() =>
                                setData({ ...data, Validation: "whitelist" })
                              }
                              data-value=""
                              className="option"
                            >
                              {t("whitelist")}
                            </li>
                            <li
                              onClick={() =>
                                setData({ ...data, Validation: "blacklist" })
                              }
                              data-value=""
                              className="option"
                            >
                              {t("blacklist")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="option-field">
                    <textarea
                      disabled={
                        data.Validation.toLowerCase() === "none" ?? true
                      }
                      value={data.List}
                      onChange={(e) =>
                        setData({ ...data, List: e.target.value })
                      }
                      type="text"
                      name=""
                      id=""
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-md-7">
                  <div className="agent-btns">
                    <button
                      onClick={handleCreateService}
                      className="primary-btn"
                    >
                      {id ? "Update" : "Create"}
                      <i className="fal fa-arrow-right"></i>
                    </button>

                    <button onClick={handleTestService} className="primary-btn">
                      {t("test")} <i className="fal fa-arrow-right"></i>
                    </button>
                    {id ? (
                      <button
                        onClick={handleDeleteService}
                        className="primary-btn delete"
                      >
                        {t("delete")} <i className="fal fa-arrow-right"></i>
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentPage;
