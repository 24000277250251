import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getAPIActionJSON } from "../../api/ApiActions";
import { useNavigate } from "react-router-dom";
import {
  errorStyle,
  passwordRegex,
  successStyle,
  websiteRegex,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { emailRegex } from "../../utils/functions/commonFunction";

const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [checkError, setCheckError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [website, setWebsite] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const onValidation = () => {
    try {
      let success = true;
      if (!website) {
        setWebsiteError(t("error-empty-field"));
        success = false;
      }
      if (!email) {
        setEmailError(t("error-empty-field"));
        success = false;
      }

      if (!password) {
        setPasswordError(t("error-empty-field"));
        success = false;
      }
      if (!confirmPassword) {
        setConfirmPasswordError(t("error-empty-field"));
        success = false;
      }
      if (!emailRegex.test(email) && email) {
        setEmailError(t("login-email-invalid-format"));
        success = false;
      }
      if (!websiteRegex.test(website) && website) {
        setWebsiteError(t("register-website-invalid-format"));
        success = false;
      }
      if (password && confirmPassword && password !== confirmPassword) {
        setConfirmPasswordError(t("error-password-unmatch"));
        setPasswordError(t("error-password-unmatch"));
        success = false;
      }
      if (password && !passwordRegex.test(password)) {
        setPasswordError(t("error-invalid-password"));
        success = false;
      }
      if (!isChecked) {
        setCheckError(t("error-unchecked"));
        success = false;
      }
      return success;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  useEffect(() => {
    setEmailError("");
  }, [email]);
  useEffect(() => {
    setPasswordError("");
  }, [password]);
  useEffect(() => {
    setWebsiteError("");
  }, [website]);
  useEffect(() => {
    setConfirmPasswordError("");
  }, [confirmPassword]);
  useEffect(() => {
    setCheckError("");
  }, [isChecked]);
  const handleRegister = () => {
    try {
      // const validated = checkValidation();
      const validated = onValidation();
      if (validated && isChecked) {
        dispatch(
          getAPIActionJSON(
            "register",
            { email: email, password: password, website: website },
            (e) => handleError(e),
            "",
            (e) => handleSuccess(e)
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleError = (response) => {
    toast.dismiss();
    toastId.current = toast.error(t("toast-signup-fail"), errorStyle);
  };
  const handleSuccess = (response) => {
    try {
      if (response.success) {
        toast.dismiss();
        toastId.current = toast.success(
          t("toast-signup-success"),
          successStyle
        );
      }
      dispatch({ type: "saveEmail", data: email });
      navigate("/activate", {
        state: { email: email, password: password, website: website },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="login-area signup-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="area-title">
              <h2>{t("signup").toUpperCase()}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="sign-note">
              <ul>
                <li>{t("signup-note-1")}</li>
                <li>{t("signup-note-2")}</li>
                <li>{t("signup-note-3")}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="login-form">
              <form action="">
                <div className="form-item">
                  <label for="">{t("website-address")}</label>
                  <div className="field-single">
                    <input
                      type="text"
                      placeholder="example.com"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      className={websiteError ? "error" : ""}
                    />
                  </div>
                  {websiteError ? (
                    <div style={{ padding: 5, color: "red", fontSize: 15 }}>
                      {websiteError}
                    </div>
                  ) : null}
                </div>
                <div className="form-item">
                  <label for="">{t("email-address")}</label>
                  <div className="field-single">
                    <input
                      type="text"
                      placeholder="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={emailError ? "error" : ""}
                    />
                  </div>
                  {emailError ? (
                    <div style={{ padding: 5, color: "red", fontSize: 15 }}>
                      {emailError}
                    </div>
                  ) : null}
                </div>
                <div className="form-item">
                  <label for="">{t("password")} </label>
                  <div className="field-single">
                    <input
                      type="password"
                      placeholder="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={passwordError ? "error" : ""}
                    />
                  </div>
                  {passwordError ? (
                    <div style={{ padding: 5, color: "red", fontSize: 15 }}>
                      {passwordError}
                    </div>
                  ) : null}
                </div>
                <div className="form-item">
                  <label for="">
                    {t("password-note")}
                    <br /> {t("confirm-pw")}
                  </label>
                  <div className="field-single">
                    <input
                      type="password"
                      placeholder="password"
                      value={confirmPassword}
                      className={confirmPasswordError ? "error" : ""}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  {confirmPasswordError ? (
                    <div style={{ padding: 5, color: "red", fontSize: 15 }}>
                      {confirmPasswordError}
                    </div>
                  ) : null}
                </div>
                <div className="signup-checkbox">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isChecked}
                      id="flexCheckDefault"
                      onChange={(e) => setIsChecked(e.target.checked)} // Step 3: Add the event handler
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      <p>
                        {t("policy-note")}{" "}
                        <a
                          onClick={() =>
                            window.open(
                              "https://monitor-24.com/terms",
                              "_blank"
                            )
                          }
                          href="#"
                        >
                          {t("terms-of-service")}{" "}
                        </a>
                        {t("and")}{" "}
                        <a
                          onClick={() =>
                            window.open(
                              "https://monitor-24.com/policy",
                              "_blank"
                            )
                          }
                          href="#"
                        >
                          {t("privacy-policy")}
                        </a>
                      </p>
                    </label>
                  </div>
                  {checkError ? (
                    <div style={{ padding: 5, color: "red", fontSize: 15 }}>
                      {checkError}
                    </div>
                  ) : null}
                </div>
                <div className="form-item">
                  <a
                    onClick={handleRegister}
                    className={`primary-btn ${!isChecked ? "disabled" : ""}`}
                  >
                    {t("create-my-account")}
                    <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
