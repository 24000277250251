import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const SideMenu = () => {
  const toastId = useRef(null);
  const isVisible = useSelector((state) => state.app.isSlideMenu);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const email = useSelector((state) => state.user.email);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch({ type: "logOut" });
    toast.dismiss();
    toastId.current = toast.success("🦄 Log out successful !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    dispatch({ type: "toggleSlideMenu", data: false });
    navigate("/login");
  };

  const closeSlideMenu = () => {
    dispatch({ type: "toggleSlideMenu", data: false });
  };

  return (
    <div className={isVisible ? "slideMenu active" : "slideMenu"}>
      <div onClick={closeSlideMenu} className="menu_close">
        <i className="fal fa-times"></i>
      </div>
      <div className="menu_list">
        <ul>
          <li>
            <a onClick={() => navigate(isLoggedIn ? "/agent-list" : "/")}>
              {t("home")}
            </a>
          </li>
          {email ? (
            <li>
              <a onClick={() => navigate("/account")}>{t("your-account")}</a>
            </li>
          ) : null}
          <li>
            <a onClick={() => navigate("/about")}>{t("learn-more")}</a>
          </li>
          <li>
            <a onClick={() => navigate("/pricing")}>{t("pricing")}</a>
          </li>
          <li>
            <a onClick={() => navigate("/faq")}>{t("faq")}</a>
          </li>
          <li>
            <a onClick={() => navigate("/support")}>{t("support")}</a>
          </li>
        </ul>
      </div>
      {email ? (
        <div className="header-right-optino">
          <p>{email}</p>
          <a onClick={handleLogout}>{t("logout")}</a>
        </div>
      ) : null}
    </div>
  );
};

export default SideMenu;
