import React from "react";

const PolicyPage = () => {
  return (
    <div style={{ margin: 10 }}>
      <h1>MONITOR-24 Privacy Policy (Last Updated: July 22nd, 2024)</h1>


      <p>
        MONITOR-24 is committed to protecting your personal information.
        MONITOR-24 uses the personal information it collects about you for the
        limited uses outlined below in this policy.
      </p>
      <p>
        This Privacy Policy sets forth the measures that MONITOR-24 takes to
        protect your personal information, the ways in which MONITOR-24 treats
        your personal information and the choices MONITOR-24 affords you with
        respect to your personal information. By using the MONITOR-24 Website,
        you consent to the terms of this Privacy Policy.
      </p>
      <p>As used in this Privacy Policy:</p>
      <p>“Services” means the services provided by the MONITOR-24 Website;</p>
      <p>
        “Website” means the MONITOR-24.com domain and any sub-domains thereof;
      </p>
      <p>
        “MONITOR-24” means MONITOR-24 and entities controlling, controlled
        by or under common control with Future Embrace Limited.
      </p>
      <p>
        This Privacy Policy may be updated from time to time including for
        purposes of adapting it to new features offered by MONITOR-24, therefore
        MONITOR-24 asks you to check back periodically.
      </p>
      <h2>Information that MONITOR-24 Collects</h2>
      <p>
        The information that we collect is aimed to allow us to provide you with
        better services and a more enjoyable experience. We may collect the
        following types of information:
      </p>
      <p>· User information that you supply when registering your account;</p>
      <p>
        · Information supplied by your browser when accessing our Website, such
        as IP address, type of browser, date and time of access, etc.;
      </p>
      <p>
        · Information sent to our Website by our software products (Agents) that
        you may download from our Website. Our Agents send only the minimal
        details allowing effective monitoring. These include the public and
        private IP address of the monitored server, host name and, in case of
        failure, the failure description. All the information collected by the
        Agents is displayed on the Website pages, protected by your username and
        password.
      </p>
      <h2>Use of Information</h2>
      <p>
        MONITOR-24 uses the information it collects for the following purposes:
      </p>
      <p>
        · Internal Use. MONITOR-24 uses the information collected for internal
        purposes, to improve its Website and Services, and to better tailor the
        Website and Services to meet your needs.
      </p>
      <p>
        · Provision of Services. MONITOR-24 uses information sent to the Website
        by the Agent software to monitor your IT resources and provide you with
        reporting as to the state of your network, servers and applications.
      </p>
      <p>
        · E-mail. MONITOR-24 may use your e-mail address to contact you
        regarding site notices and monitoring alerts. If you do not wish to
        receive such mailings, simply configure your notification settings
        accordingly.
      </p>
      <p>
        · Resolving Problems. MONITOR-24 may use information and other data it
        obtains from your current and past activities to resolve disputes and
        troubleshoot problems and to monitor and enforce the MONITOR-24 User
        Agreement.
      </p>
      <h2>Disclosure of Information</h2>
      <p>
        General Rule. MONITOR-24 will not disclose, sell or transfer any of your
        personal information, to any third parties (e.g., advertisers or
        business partners), except in the following cases:
      </p>
      <p>
        · Opt-Out. MONITOR-24 notified you of such transfer in advance and gave
        you the opportunity to choose to opt-out of such transfer.
      </p>
      <p>
        · Special Cases. MONITOR-24 has reason to believe that disclosing such
        personal information is necessary to identify, contact or bring legal
        action against someone who may be violating MONITOR-24’s terms of
        service, software license agreements or this Privacy Policy or may be
        causing injury to or interference with (either intentionally or
        unintentionally) MONITOR-24’s rights or property, other users, or anyone
        else that could be harmed by such activities. In addition, MONITOR-24
        may disclose or access a user's account in the event that MONITOR-24
        believes in good faith that the law requires such disclosure.
      </p>
      <p>
        · Merger or Sale. In the event that MONITOR-24 merges with or is
        acquired by another business entity or sells all or substantially all of
        its assets to an acquirer, MONITOR-24 may share some or all or your
        personal information with the merged or acquiring company in order to
        continue serving you and others and continuing to offer you the
        Services. MONITOR-24 will make reasonable efforts to notify you in the
        event that it shares any of your personal information with a merging or
        acquiring company.
      </p>
      <p>
        Business Partners. MONITOR-24 may at any time add business partners or
        co-branded sites to its site, but the sharing of information with them
        will be limited to only statistical information regarding users in
        general including user information provided on an anonymous basis, and
        MONITOR-24 will not disclose any of your personal information,
        correspondence (other than correspondence posted to the Website, which
        is freely accessible), or any related information.
      </p>
      <p>
        Links and Displayed Sites. MONITOR-24’s site may contain links to other
        sites. MONITOR-24 is not responsible for the privacy practices or the
        content of such linked or otherwise displayed sites.
      </p>
      <h2>Choice</h2>
      <p>
        You may, at any time, choose to have any of your personal information
        that is inaccurate deleted or amended.
      </p>
      <h2>Security</h2>
      <p>
        MONITOR-24’s site has reasonable security measures in place to protect
        the loss, misuse and unauthorized access, disclosure, alteration, and
        destruction of your personal information. Unfortunately, no data
        transmission over the Internet can be guaranteed to be 100% secure. As a
        result, while MONITOR-24 strives to protect your personal information,
        MONITOR-24 cannot ensure the security of any information that you
        transmit to MONITOR-24 or any information that you receive from
        MONITOR-24’s Service, and you transmit such information at your own
        risk. Your MONITOR-24 registered user account information is protected
        by a password for your privacy and security. Please make sure to protect
        your account information and log off when you are done using the
        MONITOR-24 Website if other users have access to the same computer.
      </p>

      <h2>Editing Registration Data</h2>
      <p>
        You may review and edit the information input by you when registering
        with the Website by using your e-mail address or User ID and Password.
        If you cancel your account, you may also have such registration data
        deleted from MONITOR-24’s database by sending your request to
        support@MONITOR-24.com. However, you should be aware that once posted,
        you cannot change or remove any public postings made to MONITOR-24’s
        Website (such as in the “Community” section). You should also be aware
        that even if you delete your registration data, there may remain
        residual information that will remain within MONITOR-24’s databases,
        access logs, and/or other records, which may or may not contain personal
        information.
      </p>

      <h2>Contact</h2>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        MONITOR-24, or any related issues, MONITOR-24 will be pleased to assist
        you. You may contact MONITOR-24 at support@MONITOR-24.com.
      </p>

      <h2>Cookies</h2>
      <p>
        You acknowledge and agree that MONITOR-24 may use cookies on its site. A
        cookie is a piece of text that can be entered into the memory of your
        hard drive and can store information about your use of MONITOR-24’s
        Services. MONITOR-24 uses cookies for session management. You can delete
        cookies from your computer at your sole discretion.
      </p>

      <h2>Subordination to the User Agreement</h2>
      <p>
        This Privacy Policy is incorporated into and subject to the terms and
        conditions of MONITOR-24’s terms of service located here as an integral
        part thereof; in case of a contradiction between any provision of the
        terms of service and the content of this document, the provisions of the
        terms of service shall prevail.  In case of a discrepancy between the English 
        and other versions of this policy, the English version shall prevail.
      </p>
    </div>
  );
};

export default PolicyPage;
