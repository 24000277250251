import React from "react";
import { RotatingLines } from "react-loader-spinner";
const LoadingOverlay = ({ loading }) => {
  // You can customize the loader's appearance here
  return (
    loading && (
      <div className="loading-overlay">
        <RotatingLines
          strokeColor="#60ae89"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    )
  );
};

export default LoadingOverlay;
