export const getAPIs = {
    login: {
        name: "login",
        path: "/www/v1/login",
        method: "POST",
    },
    register: {
        name: "register",
        path: "/www/v1/register",
        method: "POST",
    },
    activate: {
        name: "activate",
        path: "/www/v1/activate",
        method: "POST",
    },
    updateUser: {
        name: "updateUser",
        path: "/www/v1/profile",
        method: "POST",
    },
    getUser: {
        name: "getUser",
        path: "/www/v1/profile",
        method: "GET",
        note: "No such Service; Adding Soon",
    },
    changePassword: {
        name: "changePassword",
        path: "/www/v1/resetpassword/",
        method: "POST",
        note: "append reset token to request.",
    },
    forgotPassword: {
        name: "forgotPassword",
        path: "/www/v1/forgotpassword",
        method: "POST",
        note: "This will request a reset token, sent by email with a url, we need a page to receive it. will look somthing like www.monitor-24.com/passwordreset.js?token=liliuh12lih12l123lih3l1i21li2uh3l",
    },
    resetPassword: {
        name: "resetPassword",
        path: "/www/v1/resetpassword",
        method: "POST",
    },
    createService: {
        name: "createService",
        path: "/www/v1/services",
        method: "POST",
    },
    getAllService: {
        name: "getAllService",
        path: "/www/v1/getservices",
        method: "POST",
    },
    getService: {
        name: "getService",
        path: "/www/v1/getservices",
        method: "POST",
    },
    testService: {
        name: "testService",
        path: "/www/v1/servicetest",
        method: "POST",
    },
    deleteService: {
        name: "deleteService",
        path: "/www/v1/delservice",
        method: "POST",
    },
    getPlans: {
        name: "getPlans",
        path: "/www/v1/plans",
        method: "GET"
    },
    deleteAccount: {
        name: "deleteAccount",
        path: "/www/v1/profile",
        method: "DELETE"
    }
};