import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getAPIActionJSON } from "../../api/ApiActions";
import { toast } from "react-toastify";
import { errorStyle, successStyle } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const ActivationPage = () => {
  const navigate = useNavigate();
  const toastId = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const token = searchParams.get("token");
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [countdown, setCountdown] = useState(60);
  const [isClickable, setIsClickable] = useState(false);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const userEmail = useSelector((state) => state.user.email);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    if (!userEmail && !token && !code) {
      navigate("/");
      return;
    }
    code && setInputValues(code.split(""));
  }, [userEmail, token, code]);
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsClickable(true);
    }
  }, [countdown]);
  const handleResend = () => {
    if (isClickable) {
      try {
        dispatch(
          getAPIActionJSON(
            "register",
            state,
            (e) => handleError(e, "resend"),
            "",
            (e) => handleSuccess(e, "resend")
          )
        );
        setCountdown(60);
        setIsClickable(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    const allFilled = inputValues.every((value) => value !== "");
    if (allFilled) {
      handleActivate();
    }
  }, [inputValues]);

  const handleInputChange = (e, index) => {
    // Get the input value and make sure it's a single digit
    let newValue = e.target.value;
    if (newValue.length > 1) {
      newValue = newValue.slice(0, 1);
    }

    // Update the inputValues array with the new value at the specified index
    const newInputValues = [...inputValues];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);

    // Move focus to the next input field if a digit is entered
    if (newValue.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };
  const handleActivate = () => {
    try {
      // Combine the single-digit numbers into a four-digit number
      const fourDigitNumber = parseInt(inputValues.join(""), 10).toString();
      let data = {};
      if (token) {
        data = {
          token: token,
          code: fourDigitNumber,
        };
      } else {
        data = {
          email: userEmail,
          code: fourDigitNumber,
        };
      }
      dispatch(
        getAPIActionJSON(
          "activate",
          data,
          (e) => handleError(e),
          null,
          (e) => handleSuccess(e)
        )
      );
    } catch (error) {}
  };
  const handleError = (response, type) => {
    toast.dismiss();
    toastId.current = toast.error(
      type === "resend" ? t("toast-resend-fail") : t("toast-activate-fail"),
      errorStyle
    );
  };
  const handleSuccess = (response, type) => {
    if (response.success) {
      toast.dismiss();
      toastId.current = toast.success(
        type === "resend" ? t("toast-resent") : t("toast-activated"),
        successStyle
      );
      type !== "resend" && navigate("/agent-list");
    } else handleError();
  };

  return (
    <div className="activation-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="activation-wrapper">
              <div className="activation-wrap">
                <h2>{t("welcome")}</h2>
                <label for="">{t("activate-code")}</label>
                <div className="code-filed">
                  {inputValues.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      value={value}
                      maxLength="1"
                      onChange={(e) => handleInputChange(e, index)}
                      ref={inputRefs[index]}
                    />
                  ))}
                </div>
                <div>
                  <p>
                    {t("resend-message")}{" "}
                    {isClickable ? "" : `in ${countdown}s`}
                  </p>
                  <a
                    className={`resend-button ${
                      isClickable ? "active" : "inactive"
                    }`}
                    onClick={isClickable ? handleResend : null}
                  >
                    {t("resend")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationPage;
